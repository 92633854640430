export default {
  production: {
    level: 'error',
    reduxEnabled: false
  },
  default: {
    level: 'info',
    reduxEnabled: true
  }
}
